import React, { useRef, useEffect, useState } from "react";
import { graphql, navigate } from "gatsby";
import Img from "gatsby-image";
import { Helmet } from "react-helmet";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
// import Layout from "../components/layout"

import undefinedLogoWhite from "../images/undefinedLogoWhite.png";
import "./feature.css";
import Contact from "../components/contact";

var colorArr = [];

export default function BlogPost({ data }) {
  const swiper = useRef(null);
  const [curColor, setCurColor] = useState(
    colorArr[Math.floor(Math.random() * colorArr.length)]
  );
  useEffect(() => {
    //add necessary parameters required by checking the offical docs of swiper
    const cursor = document.getElementById("cursor");
    let mouseX = 0;
    let mouseY = 0;

    let cursorX = 0;
    let cursorY = 0;

    let speed = 0.3;

    function animate() {
      let distX = mouseX - cursorX;
      let distY = mouseY - cursorY;

      cursorX = cursorX + distX * speed;
      cursorY = cursorY + distY * speed;

      cursor.style.left = cursorX + "px";
      cursor.style.top = cursorY + "px";

      requestAnimationFrame(animate);
    }
    animate();

    document.addEventListener("mousemove", function (event) {
      // mouseX = event.pageX;
      // mouseY = event.pageY;
      mouseX = event.clientX;
      mouseY = event.clientY;
    });

    var hoverItems = document.getElementsByClassName("hoverable");
    Array.from(hoverItems).forEach(function (element) {
      element.addEventListener("mouseenter", function (event) {
        cursor.classList.add("active");
      });
      element.addEventListener("mouseleave", function (event) {
        cursor.classList.remove("active");
      });
    });
  }, []);
  const featureData = data.airtable.data;
  console.log("Data is: ", featureData);
  return (
    // <Layout>
    <React.Fragment>
      <div id="cursor"></div>

      <div className="feature-wrapper">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"Undefined | " + featureData.Name}</title>
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={"Undefined | " + featureData.Name}
          />
          <meta property="og:description" content={featureData.Description} />
          <meta
            property="og:url"
            content={"https://undefinedllc.com" + featureData.Slug}
          />
          <meta property="og:site_name" content="Undefined LLC" />
          {/* <link
          rel="canonical"
          href={"https://undefinedllc.com/" + featureData.Slug}
        /> */}
        </Helmet>
        <div
          style={{
            // height: 300,
            overflow: "hidden",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 100,
          }}
        >
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{
              height: "100%",
              width: "100%",
              transform: "scaleX(-1) scaleY(-1)",
            }}
          >
            <path
              d="M-1.69,-1.47 C162.52,252.13 235.32,-23.19 500.56,132.72 L500.00,150.00 L0.00,150.00 Z"
              // style={{ stroke: "none", fill: "rgb(52, 152, 219)" }}
              style={{ stroke: "none", fill: "#212121" }}
            ></path>
          </svg>
        </div>
        <img
          className="hoverable"
          src={undefinedLogoWhite}
          alt="undefined_logo"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            height: 100,
            zIndex: 100,
          }}
          onClick={() => {
            navigate("/");
          }}
        ></img>
        {/* <div dangerouslySetInnerHTML={{ __html: post.html }} /> */}
        <div className="section-portfolio-hero">
          <div
            className="section-portfolio-hero-img"
            style={{
              backgroundImage:
                "url(" + featureData.Hero?.localFiles[0].publicURL + ")",
              bottom: -300,
            }}
          ></div>
          <div style={{ marginBottom: 30 }}>
            <h4
              className="section-portfolio-hero-subtext"
              style={{ color: "yellow" }}
            >
              {/* Maybe use this color? #00FF00 */}
              {/* <span style={{ color: "yellow" }}>0{ind + 1}</span>{" "} */}
              {featureData.ShortDescription}
            </h4>
            {/* <div className="section-header">
              <h1
                className="section-header-text"
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  textAlign: "start",
                }}
              >
                {featureData.Name}
              </h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4 className="section-portfolio-hero-subtext">
                <span style={{ color: "yellow" }}>Platform</span>
                <br></br>
                {featureData.ProjectType}
              </h4>
              <h4 className="section-portfolio-hero-subtext">
                <span style={{ color: "yellow" }}>Year</span>
                <br></br>
                {featureData.CompletedDate}
              </h4>
            </div> */}
            <div
              // style={{
              //   marginLeft: 50,
              //   marginBottom: 30,
              //   width: 600,
              // }}
              className="section-container-inner"
            >
              <div className="section-header">
                <h1
                  className="section-header-text"
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    textAlign: "start",
                  }}
                >
                  {featureData.Name}
                </h1>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4 className="section-portfolio-hero-subtext">
                  <span style={{ color: "yellow" }}>Description</span>
                  <br></br>
                  {featureData.Description}
                </h4>
              </div>
            </div>
            {/* <div
              className="section-portfolio-view-button hoverable"
              onClick={() => {
                navigate("/" + featureData.Slug);
              }}
            >
              View
            </div> */}
          </div>
        </div>
        {/* <div
          className="wave-container"
          style={{
            // "scroll-snap-align": "start",
            height: "40vh",
            width: "100vw",
            background: "#e74c3c",
          }}
        >
          <div className="wave" style={{ backgroundColor: "#212121" }}></div>
        </div> */}
        {/* <div
          id="section-problem"
          className="section-container sideways-container"
          style={{
            backgroundColor: "#212121",
          }}
        >
          <div className="section-header">
            <h1 className="section-header-text sideways-text">Why Us</h1>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 40,
            }}
          >
            <div style={{ width: 700 }}>
              <h3 className="section-contact-subheader-text subtext-large">
                {featureData.Insight}
              </h3>
            </div>
          </div>
        </div> */}
        <div
          className="wave-container"
          style={{
            // "scroll-snap-align": "start",
            height: "30vh",
            width: "100vw",
            // background: "#212121",
          }}
        >
          {/* <div className="wave" style={{ backgroundColor: "black" }}></div> */}
          <div className="wave" style={{ backgroundColor: "yellow" }}></div>
        </div>
        <div
          id="section-solution"
          className="section-container sideways-container"
          style={{
            backgroundColor: "yellow",
          }}
        >
          <div className="section-header">
            <h1
              className="section-header-text sideways-text"
              style={{ color: "black" }}
            >
              Why Us
            </h1>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // marginBottom: 40,
            }}
          >
            <div style={{ width: 700 }}>
              <h3
                className="section-contact-subheader-text subtext-large"
                style={{ color: "black" }}
              >
                {featureData.Insight}
              </h3>
            </div>
          </div>
        </div>
        <div
          className="wave-container"
          style={{
            // "scroll-snap-align": "start",
            height: "30vh",
            width: "100vw",
            background: "yellow",
          }}
        >
          {/* <div className="wave" style={{ backgroundColor: "black" }}></div> */}
          <div className="wave" style={{ backgroundColor: "#212121" }}></div>
        </div>
        <div
          id="section-contact"
          className="section-container"
          style={{
            backgroundColor: "#212121",
          }}
        >
          <div className="section-header">
            <h1 className="section-header-text">Get in touch</h1>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 40,
            }}
          >
            <div style={{ width: 700 }}>
              <h3 className="section-contact-subheader-text">
                We're always looking to connect with potential clients and peers
                in the field. Feel free to either fill out the form or send us
                an email with any questions you have
              </h3>
            </div>
          </div>
          <Contact />
        </div>
      </div>
    </React.Fragment>
    // </Layout>
  );
}
export const query = graphql`
  query ServiceQuery($slug: String) {
    airtable(data: { Slug: { eq: $slug } }) {
      data {
        Name
        Slug
        ShortDescription
        Description
        Insight
        Technologies
        ProjectType
        CompletedDate
        Logo {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Hero {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
        }
      }
    }
  }
`;
